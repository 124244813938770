.logo {
  &:before {
    display: none;
  }
}

main {
  background: #f9f9f9;
  min-height: calc(100vh - 300px);
}

.content {
  padding: 45px 15px 30px;
  width: 100%;
  position: relative;
  @include media-breakpoint-up(lg) { 
    padding: 60px 0;
  }
}

.title-page {
  font-weight: 300;
  font-size: 60px;
  line-height: 1;
  margin-bottom: 45px;
  color: var(--color-primary);
}

.subtitle {
  font-size: 30px;
  margin-bottom: 50px;
  font-weight: normal;
  
}


.context {
  max-width: 665px;
  margin: 0 auto;
  font-size: 20px;

  h1,h2,h3,h4,h5,h6 {
    color: var(--color-primary);
    font-weight: normal;
  }
}

.messenges {
  max-width: 325px;
  margin: 0 auto;
  font-size: 20px;
  h2 {
    font-weight: 500;
    font-size: 30px;
  }

  .btn {
    margin-top: 50px;
  }
}

.center {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;  
  margin-bottom: 0 !important;

  img {
    max-width: 100%;
    height:auto;
  }
}

.flex-d-c {
  flex-direction: column;
}

.link-btn {
  cursor: pointer;
}

.link {
  display: inline-block;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 1px;
    background: var(--color-primary);
    width: 100%;
    transform: scale(0);
    transition: all 0.5s var(--default-effect);
  }
  &:hover, &.is-active {
    &:before {
      transform: scale(1);
    }
  }
}


.focus-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  li {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;
    padding: 0 15px;
    position: relative;
    border-left: 1px solid var(--color-text-dark);

    &:first-child {
      border: none
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.loading__inner {
  min-width: 40px;
  min-height: 40px;
  border: 5px solid rgba(0,0,0,.25);
  border-right: 5px solid var(--color-primary);
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
}