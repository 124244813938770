// COLORS
// ==========================================================================

//theme

$color-primary: #181818;
$color-secondary: #00de91;
$color-button: #3297f5;

$color-surface: #f9f9f9;

// Based from
$body-color: #000000;
$body-bg: #f9f9f9;

$color-black: #000000;
$color-gray-dark: #9b9b9b !default; // #333
$color-gray: #dcdcdc;
$color-gray-light: #f4f4f4 !default; // #999
$color-dark-gray: #D9D9D9;

$color-amber: #FFDE59;
$color-red: #FF575D;
$color-green: #A6D967;

$color-base-bg: #fff !default;
$color-base-txt: $color-black !default;
$color-base-link: $color-primary !default;
$color-base-link-hover: $color-gray-dark !default;

$color-brand-primary: $color-primary !default;
$color-brand-secondary: $color-secondary !default;
$color-heading-txt: $color-primary !default;

$link-color: $color-primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: null !default;

$text-muted: #eeeeee !default;
